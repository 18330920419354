<template>
  <section class="home-page-section teachers-section bg-white">
    <div class="home-container">
      <h3 class="home-page-section-title text-center font-weight-bold">
        Đội ngũ giáo viên
      </h3>
      <VueSlickCarousel class="teachers-wr" v-bind="carouselSettings">
        <div class="each-teacher-card" v-for="(teacher, ind) in listTeacher" :key="ind">
          <img :src="'/ui-new/images/teacher-avatar-'+(ind + 1)+'.png'" alt="teacher-avatar" class="mw-100 teacher-avatar rounded-circle d-block mx-auto" :style="{'backgroundColor': teacher.color}">
          <p class="teacher-name text-center" v-html="teacher.nameMobile"></p>
          <p class="teacher-desc text-center" v-html="teacher.address"></p>
          <p class="teacher-pos text-center" v-html="teacher.subject"></p>
        </div>
      </VueSlickCarousel>
    </div>
  </section>
</template>

<script>
export default {
  components: {
  },
  data () {
    return {
      carouselSettings: {
        dots: false,
        arrows: false,
        infinite: false,
        autoplay: true,
        pauseOnHover: true,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 1,
        responsive: [
          {
            breakpoint: 1366,
            settings: {
              slidesToShow: 4
            }
          },
          {
            breakpoint: 992,
            settings: {
              slidesToShow: 2.34,
              dots: false
            }
          },
          {
            breakpoint: 576,
            settings: {
              slidesToShow: 1.34
            }
          }
        ]
      },
      listTeacher: [
        { nameMobile: 'Cô giáo<br>Nguyệt Ca', address: 'Cử nhân hệ tài năng khoa tiếng Anh trường ĐH HN', subject: '(GV môn Tiếng Anh)', color: '#B5E3F3' },
        { nameMobile: 'Thạc sĩ<br>Hồng Lê', address: 'Trưởng bộ môn tiếng Anh kinh tế trường ĐH Giao thông vận tải', subject: '(GV môn Tiếng Anh)', color: '#EAC0C3' },
        { nameMobile: 'Tiến sĩ<br>Nguyễn Văn Thắng', address: 'Giảng viên ĐH Công nghiệp HN', subject: '(GV môn Toán Học)', color: '#B5E3F3' },
        { nameMobile: 'Thạc sĩ<br>Đỗ Thị Hoàng Anh', address: 'Giáo viên trường THPT Chu Văn An, Hà Nội', subject: '(GV môn Ngữ Văn)', color: '#FFDFC0' },
        { nameMobile: 'Thạc sĩ<br>Ngô Thanh Tùng', address: 'Thạc sĩ Vật Lí trường ĐH Bách Khoa Tomsk, Liên Bang Nga', subject: '(GV môn Vật Lý)', color: '#FFDFC0' },
        { nameMobile: 'Thạc sĩ<br>Lê Kim Huệ', address: 'Giáo viên Trường THPT chuyên Nguyễn Huệ', subject: '(GV môn Hóa Học)', color: '#ECD5FE' },
        { nameMobile: 'Cô giáo<br>Nguyễn Thị Hà', address: 'Cử nhân sư phạm Hóa Học Đại học Giáo dục', subject: '(GV môn Hóa Học)', color: '#D4FFC0' },
        { nameMobile: 'Thầy giáo<br>Hà Ngọc Duy', address: 'Cử nhân Sư phạm Toán Trường ĐHSP HN', subject: '(GV môn Toán Học)', color: '#EAC0C3' },
        { nameMobile: 'Thạc sĩ<br>Trần Thị Ngọc', address: 'Giáo viên trường Cao Đẳng Công Thương', subject: '(GV môn Toán Học)', color: '#D0D4F9' },
        { nameMobile: 'Thạc sĩ<br>Nguyễn Đức Trọng', address: 'Giáo viên trường THPT Đoàn Thị Điểm, Hà Nội', subject: '(GV môn Ngữ Văn)', color: '#C0EADE' },
        { nameMobile: 'Thạc sĩ<br>Trần Thị Thanh Huyền', address: 'Giảng viên Toán trường Sĩ quan Tăng Thiếp Giáp', subject: '(GV môn Toán Học)', color: '#EAC0C3' },
        { nameMobile: 'Thạc sĩ<br>Nga Sinh', address: 'Thủ khoa công nghệ sinh học ĐH Khoa học tự nhiên', subject: '(GV môn Sinh học)', color: '#FED5DB' },
        { nameMobile: 'Tiến sĩ<br>Triệu Quỳnh Trang', address: 'Nguyên tổ trưởng tổ Vật Lí - Kĩ thuật trường CĐSP Nam Định', subject: '(GV môn Vật Lý)', color: '#C0EADE' },
        { nameMobile: 'Cô giáo<br>Bùi Thị Trang', address: 'GV trường THCS Mậu Lương', subject: '(GV môn Tiếng Anh)', color: '#D0D4F9' },
        { nameMobile: 'Thạc sĩ<br>Trần Minh Hiếu', address: 'Giáo viên dạy giỏi cấp tỉnh, thành phố ', subject: '(GV môn Ngữ Văn)', color: '#FED5DB' },
        { nameMobile: 'Thạc sĩ<br>Lương Quỳnh Ly', address: 'Giảng viên tiếng Anh Học viện Quản lý Giáo dục', subject: '(GV môn Tiếng Anh)', color: '#ECD5FE' },
        { nameMobile: 'Thạc sĩ<br>Nguyễn Thị Nhung', address: 'Tốt nghiệp trường Leeds Beckett University Anh quốc', subject: '(GV môn Tiếng Anh)', color: '#D4FFC0' },
        { nameMobile: 'Thạc sĩ<br>Pham Ngọc Hà', address: 'Giáo viên trường THPT chuyên Nguyễn Huệ, Hà Nội.', subject: '(GV môn Sinh Học)', color: '#D0D4F9' }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
  .teachers-section {
		background: #f8f8fa;
		padding: 90px 0;
    @media (max-width: 992px) {
      padding: 26px 0 38px;
    }
    @media (max-width: 576px) {
      padding: 26px 0 23px;
    }
    .home-container {
      .home-page-section-title {
        font-size: 2.25rem;
        line-height: 56px;
        color: #1b1c20;
        margin-bottom: 2rem;
        @media (max-width: 992px) {
          font-size: 1.75rem;
        }
      }
      .teachers-wr {
        gap: 57px;
      }
      .each-teacher-card {
        background: #ffffff;
        box-shadow: 0px 0px 1.25rem rgba(0, 0, 0, 0.07) !important;
        padding: 1rem;
        height: 381px;
        border-radius: 104px 104px 0px 104px;
        @media (max-width: 992px) {
          height: 459px;
          border-radius: 149px 149px 0px 149px;
        }
        @media (max-width: 576px) {
          height: 399px;
          border-radius: 119px 119px 0px 119px;
        }
        .teacher-avatar {
          width: 181px;
          aspect-ratio: 1;
          object-fit: cover;
          margin-bottom: 1.125rem;
          object-position: top;
          @media (max-width: 992px) {
            width: 236px;
          }
          @media (max-width: 576px) {
            width: 204px;
          }
        }
        .teacher-name {
          color: #000033;
          font-weight: 600;
          font-size: 14px;
          line-height: 1.4;
          margin-bottom: 1rem;
          @media (max-width: 992px) {
            font-size: 1rem;
          }
        }
        .teacher-desc {
          font-size: 14px;
          line-height: 1.2;
          min-height: 2rem;
          vertical-align: middle;
          color: #666666;
          margin-bottom: 1rem;
          padding: 0 0.8rem;
          @media (max-width: 992px) {
            padding: 0;
            font-size: 1rem;
            margin-bottom: 1.125rem;
          }
        }
        .teacher-pos {
          font-size: 14px;
          line-height: 1.2;
          color: #666666;
          margin-bottom: 0;
          @media (max-width: 992px) {
            font-size: 1rem;
          }
        }
        &.tc-1 {
          .teacher-avatar {
            background-color: #fed5db;
          }
        }
        &.tc-2 {
          .teacher-avatar {
            background-color: #ffdfc0;
          }
        }
        &.tc-3 {
          .teacher-avatar {
            background-color: #c0eade;
          }
        }
        &.tc-4 {
          .teacher-avatar {
            background-color: #d0d4f9;
          }
        }
        &.tc-5 {
          .teacher-avatar {
            background-color: #b5e3f3;
          }
        }
      }
    }
  }
</style>
<style lang="scss">
  .teachers-section {
		.teachers-wr {
      .slick-list {
        margin: 0 -0.75rem;
        @media (max-width: 576px) {
          margin: 0 -0.625rem;
        }
        .slick-track {
          .slick-slide {
            padding: 0.625rem 1.325rem;
            @media (max-width: 992px) {
              padding: 0 0.75rem;
            }
            .each-teacher-card {
              max-width: unset;
              padding: 1.565rem 0;
              @media (max-width: 992px) {
                padding: 2rem 2rem 0;
              }
              @media (max-width: 576px) {
                padding: 1.5rem 1.5rem 0;
              }
            }
          }
        }
      }
		}
  }
</style>
