<template>
  <div v-if="banners.length" class="banner-slider">
    <VueSlickCarousel v-bind="carouselSettings">
      <BannerItem v-for="it in banners" :key="it.id" :type="it.code" />
    </VueSlickCarousel>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import BannerItem from '~/components/mlearn/layout/banner/item.vue'
export default {
  components: {
    BannerItem
  },
  props: {
    type: {
      type: String,
      default () {
        return ''
      }
    }
  },
  data () {
    return {
      banners: [],
      carouselSettings: {
        arrows: false,
        dots: false,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        speed: 2000,
        autoplay: true,
        autoplaySpeed: 4000
      }
    }
  },
  computed: {
    ...mapState([
      'banner',
      'isMobile'
    ])
  },
  created () {
    let ext = '_WEB'
    if (this.isMobile) {
      ext = '_MOBILE'
    }
    this.banners = this.banner.filter(it => it.code.includes(this.type + ext))
  }
}
</script>

<style lang="scss">
  .banner-slider{
    .slick-initialized{
      // .slick-slide{
      //   opacity: 0;
      //   transition: all 1s ease-in-out;
      //   transform: rotate(360deg) scale(0,0);
      //   &.slick-active{
      //     opacity: 1;
      //     transform: scale(1,1);
      //   }
      // }
    }
  }
</style>
