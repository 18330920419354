<template>
  <section class="home-page-section lib-n-supplies-section">
    <div class="home-container">
      <div class="ml-goi-cuoc container mb-5">
        <!--    <div class="breadcrumd-custom"><nuxt-link to="/">Hỏi bài</nuxt-link> <img src="~/mlearn/icons/breadcrumd.svg" alt="" class="icon" /> <span class="active">Gói cước</span></div>-->
        <div class="row list-sub">
          <div class="col-lg-4 pb-3" v-for="sub in listSub" :key="sub.id">
            <div class="sub-item">
              <div class="title font-weight-bold text-lg-center">{{ sub.name }} <span class="type d-lg-none">({{ sub.title }})</span></div>
              <div class="price text-lg-center">{{ $helper.formatMoney(sub.price, '.') }}đ <span>/{{ sub.unit }}</span></div>
              <div class="type text-center d-none d-lg-block">{{ sub.title }}</div>
              <div class="other" v-html="sub.description"></div>
              <div class="action text-right">
                <button @click="showMessage(sub)">{{ sub.register ? 'Hủy' : 'Đăng ký' }}</button>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-5">
          <div class="col-md-6 col-sm-12">
            <p class="guide-info">
              <span class="font-weight-bold">Đăng ký gói ngày soạn:</span><br>
              DK ML gửi 9285<br>
              <span class="font-weight-bold">Đăng ký gói tuần soạn:</span><br>
              Soạn DK ML7 gửi 9285<br>
              <span class="font-weight-bold">Hủy đăng ký dịch vụ:</span><br>
              Soạn HUY (mã gói) gửi 9285
            </p>
          </div>
          <div class="col-md-6 col-sm-12">
            <p class="guide-info">
              <span class="font-weight-bold">Xem hướng dẫn:</span><br>
              Soạn HD ML gửi 9285<br>
              <span class="font-weight-bold">Kiểm tra dịch vụ:</span><br>
              Soạn KT ML gửi 9285<br>
              <span class="font-weight-bold">Lấy lại Mật khẩu:</span><br>
              Soạn MK ML gửi 9285.
            </p>
          </div>
        </div>
        <b-modal hide-footer hide-header id="modal-notify-sub" centered v-model="showModal">
          <img src="~/mlearn/icons/modal/close-modal.svg" alt="close" class="close position-absolute pointer" @click="showModal = false"/>
          <div class="title">Thông báo</div>
          <div class="description">{{ message }}</div>
        </b-modal>
      </div>
    </div>
  </section>
</template>
<script>
import Resource from '~/common/api/resource'

const subResource = new Resource('price/sub')
export default {
  layout: 'mlearn',
  data () {
    return {
      showModal: false,
      listSub: [],
      message: ''
    }
  },
  computed: {
    isMobile () {
      return this.$store.state.isMobile
    }
  },
  created () {
    this.loadSub()
  },
  methods: {
    showMessage (sub) {
      if (this.isMobile) {
        window.open(`sms:${sub.service_number}?body=${sub.register ? sub.unreg_syntax : sub.reg_syntax}`, '_blank')
        return
      }
      this.showModal = true
      this.message = `Để xác nhận ${sub.register ? 'hủy' : 'đăng ký'} gói ${sub.unit}, mời Quý khách soạn tin ${sub.register ? sub.unreg_syntax : sub.reg_syntax} gửi ${sub.service_number} xác nhận ${sub.register ? 'hủy' : 'đăng ký'} DV. Phí DV ${this.$helper.formatMoney(sub.price, '.') + 'đồng/' + sub.unit.toLowerCase()}, ${sub.title.toLowerCase()}.`
    },
    async loadSub () {
      const {data} = await subResource.list()
      this.listSub = data
    }
  }
}
</script>

<style lang="scss" scoped>
.guide-info {
  margin: 0 auto;
  width: 50%;
  font-size: 20px;
  @media (max-width: 576px) {
    width: 100%;
    padding-left: 15px;
  }
}

.ml-goi-cuoc {
  .list-sub {
    .sub-item {
      position: relative;
      background: var(--white);
      box-shadow: 0px 2px 4px rgba(202, 202, 202, 0.34);
      border-radius: 8px;
      padding: 24px;
      border: 1px solid #529fe8;
      @media (max-width: 576px) {
        padding: 16px;
      }

      &::after {
        content: '';
        position: absolute;
        background: #4CA3EC;
        box-shadow: 0px 2px 4px rgba(202, 202, 202, 0.34);
        border-radius: 0px 0px 8px 8px;
        height: 8px;
        width: 100%;
        bottom: 0;
        left: 0;
        @media (max-width: 576px) {
          border-radius: 8px 0px 0px 8px;
          height: 100%;
          width: 8px;
          bottom: 0;
          left: 0;
        }
      }

      .title {
        font-size: 16px;

        .type {
          font-size: 12px;
          color: #65676B;
        }
      }

      .price {
        font-weight: 600;
        color: var(--primary);
        font-size: 26px;
        @media (max-width: 576px) {
          padding-top: 16px;
        }

        span {
          font-size: 22px;
          @media (max-width: 576px) {
          }
        }
      }

      .type {
        font-size: 12px;
        color: #65676B;
      }

      .other {
        color: #65676B;
        white-space: break-spaces;
        line-height: 200%;
        font-size: 16px;
        margin-top: 10px;
        @media (max-width: 576px) {
          font-size: 13px;
          margin-top: 8px;
        }
      }

      .action {
        margin-top: 40px;
        @media (max-width: 576px) {
          margin-top: 16px;
        }

        button {
          background: var(--primary);
          box-shadow: 0px 2px 4px rgba(202, 202, 202, 0.34);
          border-radius: 8px;
          font-weight: 500;
          font-size: 16px;
          text-align: center;
          color: var(--white);
          width: 100%;
          padding: 14px 0;
          @media (max-width: 1366px) {
            padding: 14px 0;
          }
          @media (max-width: 576px) {
            padding: 10px 30px;
            width: auto;
          }

          &:hover {
            box-shadow: 0px 3px 6px rgba(202, 202, 202, 0.34);
          }
        }
      }
    }
  }
}
</style>
<style lang="scss">
#modal-notify-sub {
  .modal-dialog {
    max-width: 585px;
    @media (max-width: 576px) {
    }

    .modal-content {
      .modal-body {
        padding: 24px;
        text-align: center;
        @media (max-width: 1366px) {
          padding: 18px;
        }
        @media (max-width: 576px) {
          padding: 16px;
        }

        .close {
          right: 15px;
          top: 15px;
        }

        .title {
          font-weight: bold;
          font-size: 24px;
          @media (max-width: 1366px) {
            font-size: 18px;
          }
          @media (max-width: 576px) {
            font-size: 16px;
          }
        }

        .description {
          margin-top: 20px;
          font-size: 16px;
          @media (max-width: 576px) {
          }
        }
      }
    }
  }
}
</style>
